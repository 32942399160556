import React from 'react'


import Button, { PRIMARY } from '@components/button'

import AlertDrawer from './alert-modal'
import { CloseModalIcon, Comment, Emoji, Message, Modal, Paragraph, Title } from './style'

import { arrayOf, func, string } from 'prop-types'

const VerificationDeclined = ({ alertKey, setAlertTypeVisibility, declinedReasons, declinedComment }) => {
  const onClick = () => {
    setAlertTypeVisibility(alertKey, false)
  }

  const reasons = declinedReasons.map((reason, i) => {
    return (
      <div style={{ marginBottom: '1rem' }} key={i}>
        {reason}
      </div>
    )
  })

  const comments =
    declinedComment && declinedComment.length > 0 ? (
      <Comment>
        <Title>Comments:</Title>
        <Paragraph>{declinedComment}</Paragraph>
      </Comment>
    ) : null

  return (
    <AlertDrawer open onRequestClose={onClick}>
      <CloseModalIcon onClick={onClick} src="/static/icons/close.svg" />
      <Modal>
        <Message>
          <Title>We had some issues verifying your account</Title>
          <Emoji>😢</Emoji>
          <Paragraph>{reasons}</Paragraph>
          {comments}
        </Message>
        <Button buttonType={PRIMARY} onClick={onClick}>
          Fix it
        </Button>
      </Modal>
    </AlertDrawer>
  )
}

export default VerificationDeclined

VerificationDeclined.propTypes = {
  alertKey: string,
  setAlertTypeVisibility: func,
  declinedReasons: arrayOf([string]),
  declinedComment: string
}
