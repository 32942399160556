import { defaultCallback } from '@helpers/callbacks'
import { track } from 'analytics'
import api, { apiV2 } from 'api'
import errorHandler from 'error-handler'
import {
  ACH_PAYMENT_METHOD,
  ACH_TOKENS,
  CREDIT_PAYMENT_METHOD,
  DEBIT_CARD_ONLINE_METHOD,
  paymentTypeTrackingOptions
} from 'helpers/payment'
import { getPayloadFromState } from 'redux/analytics/get-payloads'
import { logout } from 'redux/app/actions'
import { getUserId, getToken } from 'redux/auth/selectors'
import {
  getACH,
  getActiveCard,
  getCards,
  getDebitCards,
  getDispensaryId,
  getPaymentProviders
} from 'redux/payments/selectors'
import { requestQuote } from 'redux/quote/actions'

import t from './actionTypes'

export function fetchAndSwitchPaymentMethod(method) {
  return async (dispatch) => {
    await dispatch(fetchPaymentMethods())
    await dispatch(switchPaymentMethod(method))
  }
}

// fetches providers then fetches each providers paymentMethods
export function fetchPaymentMethods() {
  return async (dispatch, getState) => {
    dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADING })
    const xAuthToken = getToken(getState())
    // bail if the user isn't logged in!
    // this route requires auth
    if (!xAuthToken) {
      dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })
      return
    }

    // get providers
    const dispensaryId = await getDispensaryId(getState())
    if (!dispensaryId) {
      return
    }

    const { err, data } = await apiV2.fetchDispensaryProviders({ dispensaryId, enabled: true })
    if (err) {
      if (err.statusCode === 401) {
        return dispatch(logout())
      } else {
        return errorHandler(err)
      }
    }

    const promises = []
    // get saved cards/accounts for each provider
    data.forEach((providerResponse) => {
      const provider = providerResponse.provider
      dispatch(setPaymentProvider(provider))
      if (provider.paymentMethod === DEBIT_CARD_ONLINE_METHOD || provider.paymentMethod === CREDIT_PAYMENT_METHOD) {
        promises.push(dispatch(fetchCards(provider)))
      }
      if (provider.paymentMethod === ACH_PAYMENT_METHOD) {
        promises.push(dispatch(fetchBankAccounts(provider)))
      }
      dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })
    })
    return Promise.all(promises)
  }
}

function fetchCards(provider) {
  return async (dispatch, getState) => {
    dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADING })
    const userId = getUserId(getState())
    const xAuthToken = getToken(getState())
    const paymentMethod = provider.paymentMethod
    // bail if the user isn't logged in!
    // this route requires auth
    if (!xAuthToken) {
      dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })
      return
    }
    if (paymentMethod === DEBIT_CARD_ONLINE_METHOD) {
      dispatch(setDebitPublicKey())
    }
    const { err, data } = await apiV2.getCards({ providerId: provider.id, userId })
    dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })

    if (err) {
      if (err.statusCode === 401) {
        return dispatch(logout())
      } else {
        return errorHandler(err)
      }
    }

    if (data && data.length) {
      const cards = {}
      data.forEach((card) => {
        card.paymentMethod = provider.paymentMethod
        cards[card.id] = card
      })
      if (paymentMethod === DEBIT_CARD_ONLINE_METHOD) {
        dispatch(setDebitCards(cards))
      } else if (paymentMethod === CREDIT_PAYMENT_METHOD) {
        dispatch(setCreditCards(cards))
      }
    }
  }
}

export function fetchBankAccounts(provider) {
  return async (dispatch, getState) => {
    dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADING })
    const userId = getUserId(getState())
    const xAuthToken = getToken(getState())
    // bail if the user isn't logged in!
    // this route requires auth
    if (!xAuthToken) {
      dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })
      return
    }

    const { err, data } = await apiV2.getBankAccounts({ providerId: provider.id, userId })
    dispatch({ type: t.FETCH_PAYMENT_METHODS_LOADED })

    if (err) {
      if (err.statusCode === 401) {
        return dispatch(logout())
      } else {
        return errorHandler(err)
      }
    }

    // User can only have one account at a time
    if (data && data.length) {
      const ach = {
        ...data[0],
        name: data[0].name || 'Checking Account'
      }

      await dispatch(setACH(ach))
    } else {
      // Zooyza bank account tokens are not saved until order submission, so user could have ACH info in sessionstorage
      const achTokens = window.sessionStorage.getItem(ACH_TOKENS)
      const ach = achTokens ? JSON.parse(achTokens) : null
      dispatch(setACH(ach))
    }
  }
}

export function setCreditCard(card) {
  return (dispatch) => {
    track('PaymentMethod.Select', { paymentType: paymentTypeTrackingOptions[CREDIT_PAYMENT_METHOD] })
    dispatch(switchPaymentMethod(CREDIT_PAYMENT_METHOD))
    dispatch({
      type: t.SET_CREDIT_CARD,
      payload: card
    })
  }
}

export function setActiveCard(cardId, paymentType) {
  return (dispatch, getState) => {
    const cards = paymentType === DEBIT_CARD_ONLINE_METHOD ? getDebitCards(getState()) : getCards(getState())
    const card = cards[cardId]
    dispatch({
      type: t.SET_ACTIVE_CARD,
      payload: card
    })
  }
}

export function clearActiveCard(cardId) {
  return (dispatch) => {
    dispatch({
      type: t.SET_ACTIVE_CARD,
      payload: {}
    })
  }
}

export function setCreditCards(cards) {
  return {
    type: t.SET_CREDIT_CARDS,
    payload: cards
  }
}

export function setDebitCards(debitCards) {
  return {
    type: t.SET_DEBIT_CARDS,
    payload: debitCards
  }
}

export function unsetCreditCard(cardId) {
  return (dispatch, getState) => {
    const cards = getCards(getState())
    const newState = { ...cards }

    delete newState[cardId]
    dispatch({
      type: t.SET_CREDIT_CARDS,
      payload: newState
    })
  }
}

export function unsetActivePaymentId() {
  return (dispatch) => {
    dispatch(clearActiveCard())
    dispatch({
      type: t.SWITCH_PAYMENT_METHOD,
      payload: null
    })
  }
}

export function setActiveCardCvc(cvc) {
  return {
    type: t.SET_ACTIVE_CARD_CVC,
    payload: cvc
  }
}

export function savedCreditCard() {
  return (dispatch, getState) => {
    track('Checkout.Credit.Saved', getPayloadFromState(getState()))
    dispatch({ type: t.SAVED_CREDIT_CARD })
  }
}

// this is used to switch {activePaymentId} which is stored
// on the top level of the payments store.
// {activePaymentId: 0} === cash
// {activePaymentId: [uuid]} === credit card
// {activePaymentId: 2} === card on delivery
// {activePaymentId: 3} === debit (pin required) on delivery
// {activePaymentId: 4} === debit cash back on delivery
// {activePaymentId: 5} === ACh
// {activePaymentId: 6} === Debit Online
export function switchPaymentMethod(value) {
  return (dispatch, getState) => {
    const ach = getACH(getState())
    if (value === ACH_PAYMENT_METHOD && !ach) {
      console.error('Attempted to switch to ACH with no bank account added.')
      return
    }
    dispatch({
      type: t.SWITCH_PAYMENT_METHOD,
      payload: value
    })
    dispatch(requestQuote(defaultCallback, true))
  }
}

// Delete credit card
export function deleteCard(cardId) {
  return (dispatch, getState) => {
    // Need to add route if we ever enable CCs again
    dispatch({ type: t.DELETE_CARD_LOADING })
    dispatch({ type: t.DELETE_CARD_LOADED })
  }
}

// Delete debit card
export function deleteDebitCard(cardId) {
  return async (dispatch, getState) => {
    const userId = getUserId(getState())
    dispatch({ type: t.DELETE_CARD_LOADING })

    const resp = await apiV2.deleteCard({ userId, cardId })
    dispatch({ type: t.DELETE_CARD_LOADED })
    if (resp.err) {
      return errorHandler(resp.err)
    } else {
      const debitCards = getDebitCards(getState())
      const newState = { ...debitCards }
      delete newState[cardId]
      dispatch({
        type: t.SET_DEBIT_CARDS,
        payload: newState
      })

      const activeCard = getActiveCard(getState())
      if (activeCard.id === cardId) {
        dispatch(unsetActivePaymentId())
      }
    }
  }
}
// Delete ACH
export function deleteACH() {
  return async (dispatch, getState) => {
    const userId = getUserId(getState())
    const ach = getACH(getState())
    dispatch(unsetActivePaymentId())
    // if access token exists, account hasnt been saved on the BE
    if (ach.accessToken) {
      window.sessionStorage.removeItem(ACH_TOKENS)
      dispatch(setACH(null))
      return
    }
    const resp = await apiV2.deleteBankAccount({ userId, bankAccountId: ach.id })
    if (resp.err) {
      return errorHandler(resp.err)
    } else {
      dispatch(setACH(null))
    }
  }
}

export function setACH(ach) {
  return {
    type: t.SET_ACH,
    payload: ach
  }
}

export function setACHAddress(address) {
  return (dispatch, getState) => {
    const ach = getACH(getState())

    dispatch({
      type: t.SET_ACH,
      payload: {
        ...ach,
        address
      }
    })
  }
}

export function addAchFromQuery(queryParams) {
  return async (dispatch, getState) => {
    const state = getState()
    const paymentProviders = getPaymentProviders(state)
    const achClient = paymentProviders?.[ACH_PAYMENT_METHOD]?.client

    const ach = {
      accountMask: queryParams.ano,
      accessToken: queryParams.vtkn,
      name: queryParams.anm
    }
    window.sessionStorage.setItem(ACH_TOKENS, JSON.stringify(ach))
    dispatch(setACH(ach))
    track('PaymentMethod.Select', {
      paymentType: paymentTypeTrackingOptions[ACH_PAYMENT_METHOD],
      paymentProvider: achClient
    })
    dispatch(switchPaymentMethod(ACH_PAYMENT_METHOD))
  }
}

export function setDebitPublicKey() {
  return async (dispatch, getState) => {
    api.getProviderPublicKeys({}, (err, res) => {
      if (err) {
        return errorHandler(err)
      } else {
        dispatch({
          type: t.SET_DEBIT_PUBLIC_KEY,
          payload: res
        })
      }
    })
  }
}

export function setPaymentProvider(provider) {
  return (dispatch) => {
    dispatch({
      type: t.SET_PAYMENT_PROVIDERS,
      payload: provider
    })
  }
}

export function setDriverTip(amount) {
  return (dispatch) => {
    dispatch({
      type: t.SET_DRIVER_TIP,
      payload: amount
    })
  }
}
