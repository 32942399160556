import { Dispatch } from 'react'

import window from 'global/window'
import { Router } from 'next/router'
import { ActionCreator } from 'redux'

import { DEFAULT_MENU_SLUG, EAZE_ORIGIN } from '@helpers/constants'
import getPath from '@helpers/path'
import { track } from 'analytics'
import api from 'helpers/api'
import errorHandler from 'helpers/error-handler'
import { CASH_PAYMENT_METHOD, CREDIT_PAYMENT_METHOD } from 'helpers/payment'
import { quoteLoaded, quoteLoading } from 'redux/loading/actions'
import { allowCreditCards, getActivePaymentId } from 'redux/payments/selectors'
import { openApplyPromo, receiveApplyPromo, requestApplyPromo, resetPromo, setPromo } from 'redux/promo/actions'

import { rehydrateCookies } from '../cookies/actions'


export const PROMO_KEY = 'promo_code'

export function sendApplyPromo(): ActionCreator<void> {
  return (dispatch, getState) => {
    const state = getState()
    const { cart, promo, user, cookies } = state
    const userId = user.userId || cookies.userId
    const code = (promo.code || '').trim()
    const id = cart[DEFAULT_MENU_SLUG].id || cookies.cartId

    if (code === '') return dispatch(resetPromo())

    let paymentMethod = CASH_PAYMENT_METHOD

    if (allowCreditCards(state) && getActivePaymentId(state)) {
      paymentMethod = CREDIT_PAYMENT_METHOD
    }

    if (!userId) {
      dispatch(resetPromo())
      return dispatch(receiveApplyPromo({ success: false, error: 'Please log in' }))
    }

    dispatch(quoteLoading())
    dispatch(requestApplyPromo())

    return api.requestQuote({ promoCode: code, cartId: id, origin: EAZE_ORIGIN, paymentMethod }, (err, res: {priceInfo: {promoCredit: number, inviteCredit: number}}) => {
      let payload = {}
      dispatch(quoteLoaded())
      if (err) {
        dispatch(deletePromo())
        payload = {
          ...payload,
          error: err.message,
          success: false
        }
        track('Modal.PromoApply.Error', { error: err.message })
        errorHandler(err)
      }

      if (res) {
        dispatch(savePromo(code))
        window && window.sessionStorage.setItem(PROMO_KEY, code)
        payload = {
          ...payload,
          amount: res.priceInfo.promoCredit + res.priceInfo.inviteCredit,
          success: true,
          error: false
        }
      }
      dispatch(receiveApplyPromo(payload))
    })
  }
}

export function deletePromo(): ActionCreator<void> {
  return (dispatch) => {
    window && window.sessionStorage.removeItem(PROMO_KEY)
    dispatch(resetPromo())
  }
}

export function getPromo(): ActionCreator<void> {
  return (dispatch) => {
    if (window && window.sessionStorage.getItem(PROMO_KEY)) {
      dispatch(setPromo(window.sessionStorage.getItem(PROMO_KEY)))
      rehydrateCookies(dispatch)
      dispatch(sendApplyPromo())
    }
  }
}

export function savePromo(code: string): ActionCreator<void> {
  return (dispatch) => {
    window && window.sessionStorage.setItem(PROMO_KEY, code.toUpperCase())
    dispatch(setPromo(code))
  }
}

export function openPromoModal(router: Router): ActionCreator<void> {
  return (dispatch) => {
    const { href, asPath } = getPath(router, { addParams: { applyPromo: true }, removeParams: ['cart'] })
    track('Modal.PromoApply.View')
    router.push(href, asPath, { shallow: true })
    dispatch(openApplyPromo())
  }
}
