import React, { PureComponent } from 'react'


import Button, { PRIMARY } from '@components/button'
import { track } from 'helpers/analytics'

import AlertDrawer from './alert-modal'
import { CloseModalIcon, Emoji, Message, Modal, Paragraph, Title } from './style'
import { TEST_IDS } from './test/constants'

import { func, string, object } from 'prop-types'

export default class CreditCardsDisabled extends PureComponent {
  static propTypes = {
    activeDepot: object,
    alertKey: string,
    resetCurrentAction: func,
    setAlertTypeVisibility: func
  }

  componentDidMount() {
    const { activeDepot } = this.props
    track('CashOnly.Modal', { depotId: activeDepot.id })
  }

  handleClick = () => {
    const { alertKey, resetCurrentAction, setAlertTypeVisibility } = this.props
    resetCurrentAction()
    setAlertTypeVisibility(alertKey, false)
  }

  render() {
    return (
      <AlertDrawer open onRequestClose={this.handleClick}>
        <CloseModalIcon onClick={this.handleClick} src="/static/icons/close.svg" />
        <Modal data-e2eid={TEST_IDS.CASH_ONLY_COMPONENT}>
          <Message>
            <Title>Cash Only</Title>
            <Emoji>💵</Emoji>
            <Paragraph>We are temporarily experiencing payment issues. Apologies for the inconvenience.</Paragraph>
          </Message>
          <Button buttonType={PRIMARY} onClick={this.handleClick} data-e2eid={TEST_IDS.CASH_ONLY_DISMISS_BUTTON}>
            OK
          </Button>
        </Modal>
      </AlertDrawer>
    )
  }
}
