import React from 'react'


import { Checkbox, Constants, Form } from 'components/form-inputs'

import AlertDrawer from './alert-modal'
import { MelonTextDiv, MelonTextSpan, Message, Modal, ParagraphDiv, Title } from './style'

import { func, string } from 'prop-types'

const NewCreditCard = ({ resetNewCardSaved, chargeDescriptor }) => {
  const onRequestClose = () => {
    resetNewCardSaved()
  }

  return (
    <AlertDrawer open allowClose={false} onRequestClose={onRequestClose}>
      <Modal>
        <Message>
          <Title>Credit Card Privacy</Title>

          <ParagraphDiv>
            To protect your privacy, charges will appear on your statement as
            <MelonTextDiv>{chargeDescriptor}</MelonTextDiv>
            Your bank may charge an international transaction fee for purchases on Eaze. Contact us if you have any
            questions regarding billing.
          </ParagraphDiv>
        </Message>
        <Form onSubmit={onRequestClose} submitText="Continue" includeButton>
          <Checkbox name="easyMedPayAgreement" type={Constants.AGREEMENT} required>
            I understand charges will appear on my account under <MelonTextSpan>{chargeDescriptor}</MelonTextSpan>.
          </Checkbox>
        </Form>
      </Modal>
    </AlertDrawer>
  )
}

export default NewCreditCard

NewCreditCard.propTypes = {
  chargeDescriptor: string,
  resetNewCardSaved: func
}
