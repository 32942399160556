import React, { PureComponent } from 'react'


import Button, { PRIMARY } from '@components/button'
import ROUTES from 'helpers/routes'

import AlertDrawer from './alert-modal'
import { ButtonWrapper, Emoji, Logout, LogoutLink, Message, Modal, Paragraph, Title } from './style'

import { func, object } from 'prop-types'

export default class PhoneVerificationIncomplete extends PureComponent {
  static propTypes = {
    logout: func,
    router: object
  }

  handleNavigation = () => {
    this.props.router.push(ROUTES.VERIFY)
  }

  render() {
    const { logout } = this.props

    return (
      <AlertDrawer open allowClose={false} onRequestClose={() => {}}>
        <Modal>
          <Message>
            <Title>Verify your phone</Title>
            <Emoji>📱</Emoji>
            <Paragraph>To complete Eaze sign up, you need to verify your phone number.</Paragraph>
          </Message>
          <ButtonWrapper>
            <Button buttonType={PRIMARY} onClick={this.handleNavigation}>
              Get verified
            </Button>
          </ButtonWrapper>
          <Logout>
            <LogoutLink href="/login" onClick={logout}>
              Sign in as another user
            </LogoutLink>
          </Logout>
        </Modal>
      </AlertDrawer>
    )
  }
}
