import t from './actionTypes'

export function setDismissedAnnouncements(id) {
  return {
    type: t.SET_DISMISSED_ANNOUNCEMENTS,
    id
  }
}

export function removeDismissedAnnouncements() {
  return {
    type: t.REMOVE_DISMISSED_ANNOUNCEMENTS
  }
}
